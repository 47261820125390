@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html,
body,
#root {
}

.fixed-container {
  position: fixed;
  top: 65px;
  left: 0;
  width: 100%;
}
